.RichEditor-root {
  margin-top: 16px;
  margin-bottom: 8px;
  background: #fff;
  border: 1px solid #ddd;
  /* font-family: 'Georgia', serif; */
  font-size: 14px;
  padding: 10px 12px 10px;
  width: 100%;
}

.RichEditor-root.error {
  border: 2px solid #f44336;
}

.RichEditor-root .label {
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.54);
}

.RichEditor-root .label.error {
  color: #f44336;
}

.RichEditor-helper-text {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  line-height: 1em;
}

.RichEditor-helper-text.error {
  color: #f44336;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor ul,
.RichEditor-editor ol {
  padding: 0 !important;
  margin-left: 1.5em !important;
}

.RichEditor-editor ul,
.RichEditor-editor ul > li {
  list-style: initial !important;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  /* font-family: 'Hoefler Text', 'Georgia', serif; */
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  /* font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace; */
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  /* font-family: 'Helvetica', sans-serif; */
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #484848;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
  background: aliceblue;
}

.DraftEditor-root {
  overflow: scroll;
  min-height: 350px;
  max-height: 350px;
}